import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Arrow from '@/assets/icons/arrow_bk.svg';
import Logo from '@/assets/logo/logo-small.svg';
import ReactMarkdown from 'react-markdown';

type PageContextProps = {
  id: string;
  locale: string;
};

type DataProps = {
  datoCmsLinktree: any;
};

const Linktree = ({ data }: PageProps<DataProps, PageContextProps>) => {
  const content = data.datoCmsLinktree;

  return (
    <div className="bg-linktree-100 min-h-screen">
      <div className="px-4 max-w-md mx-auto">
        <div className="py-4">
          <Logo />
        </div>
        <div className="flex space-x-5 items-top">
          <div className="w-24">
            <GatsbyImage
              image={content.image?.gatsbyImageData}
              alt={content?.title}
            />
          </div>
          <div className="grow -mb-6">
            <div className="text-3xl font-medium -mb-2">{content?.title}</div>
            <ReactMarkdown children={content?.description}></ReactMarkdown>
          </div>
        </div>
        <div className="pt-6 pb-6 text-xs uppercase font-medium tracking-widest">
          {content?.subtitle}
        </div>
        <div className="space-y-4 pb-12">
          {content.links.map((item: any) => (
            <a
              href={item.link}
              className="flex h-24 items-center bg-linktree-200 p-5 rounded-xl no-underline text-medium"
            >
              <div className="text-lg grow">{item.title}</div>
              <Arrow />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Linktree;

export const query = graphql`
  query ($id: String) {
    datoCmsLinktree(id: { eq: $id }) {
      id
      title
      description
      subtitle
      image {
        gatsbyImageData(aspectRatio: 1, imgixParams: { ar: "1", fit: "cover" })
      }
      links {
        id
        title
        link
      }
    }
  }
`;
